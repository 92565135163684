/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql, StaticQuery } from 'gatsby';
import { FaUser, FaUserTie } from 'react-icons/fa';

import { breakpoints, colors, dimensions } from '../../styles/variables';
import { Button, Modal, PageWrapper, RenderMarkdown } from '../atoms';
import { BROKER_SPACE_URL, CLIENT_SPACE_URL } from './LoginButtonAndModal';

import logo from '../../images/logo-blanc.png';
import { useBrokerModal } from './BrokerModal';

export type FooterSection = {
  name: string;
  data: [
    {
      label: string;
      link: string;
    },
  ];
};

const Wrapper = styled.footer``;

const FooterDatas = styled.div`
  color: ${colors.white};

  @media screen and (min-width: ${breakpoints.lg}) {
    background-image: linear-gradient(
      90deg,
      ${colors.darkLila} 0%,
      ${colors.darkLila} 60%,
      ${colors.purple} 60%,
      ${colors.purple} 60%
    );
  }
`;

const FooterDatasContentWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${breakpoints.xl};
`;

const FooterDatasContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap;

  @media screen and (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;

const FooterSectionsContainer = styled.div`
  background-color: ${colors.darkLila};
  flex: 3;
  padding-left: ${dimensions.space.base};
  padding-right: ${dimensions.space.base};
`;

const FooterSectionsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 100px;
  row-gap: 20px;
  margin: 40px 0;
`;

const Section = styled.div``;

const SectionTitle = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 4px;

  &:before {
    content: '';
    position: absolute;
    border-bottom: 1px solid ${colors.white};
    width: 76px;
    height: 1px;
    bottom: 0;
  }
`;

const SectionList = styled.ul`
  list-style: none;
  padding: 20px 0 0;
  margin: 0;
`;

const SectionItem = styled.li`
  padding-bottom: 8px;
  font-size: 15px;
`;

const SectionLink = styled.a`
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const FooterInfosContainer = styled.div`
  background-color: ${colors.purple};
  flex: 2;
  padding-left: ${dimensions.space.base};
  padding-right: ${dimensions.space.base};
`;

const FooterInfosContent = styled.div`
  margin: 40px 20px;
  font-size: 15px;
`;

const FooterInfosText = styled.div`
  padding-top: 50px;
  font-size: 0.75rem;
`;

const FooterLinkContainer = styled.div`
  font-size: 14px;
  background-color: ${colors.white};
`;

const FooterLinkList = styled.ul`
  margin: 0;
  padding: 0;
`;

const FooterLink = styled.li`
  display: inline;

  &:not(:last-child)::after {
    content: ' - ';
  }

  & a {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

type FooterLinkItemProps = {
  name: string;
  content: string;
};

const FooterLinkItem: React.FC<FooterLinkItemProps> = ({ name, content }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <FooterLink>
        <a
          style={{ cursor: 'pointer' }}
          role="button"
          tabIndex={0}
          onClick={() => setShow(true)}
          onKeyDown={(e) => (e.key === 'Enter' ? setShow(true) : {})}
        >
          {name}
        </a>
      </FooterLink>
      <Modal show={show} onClose={() => setShow(false)} title={name}>
        <RenderMarkdown>{content}</RenderMarkdown>
      </Modal>
    </>
  );
};

const LoginButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
`;

type Props = {
  strapiFooterLayout: {
    about: string;
  };
  // allStrapiFooterSection: [
  //   {
  //     node: FooterSection;
  //   },
  // ];
  // allStrapiFooterSection: {
  //   edges: {
  //     node: FooterSection;
  //   };
  // };
  allStrapiFooterSection: any;
  // allStrapiOther: {
  //   edges: {
  //     node: {
  //       name: string;
  //       content: string;
  //     };
  //   };
  // };
  allStrapiOther: any;
};

const Footer: React.FC = () => {
  const { open } = useBrokerModal();
  return (
    <StaticQuery
      query={graphql`
        query footerSections {
          strapiFooterLayout {
            about
          }
          allStrapiFooterSection {
            edges {
              node {
                id
                name
                data {
                  label
                  link
                }
              }
            }
          }
          allStrapiOther {
            edges {
              node {
                name
                content
              }
            }
          }
        }
      `}
      render={(data: Props) => (
        <Wrapper>
          <FooterDatas>
            <FooterDatasContentWrapper>
              <FooterDatasContent>
                <FooterSectionsContainer>
                  <FooterSectionsContent>
                    {data.allStrapiFooterSection.edges.map(({ node: section }) => (
                      <Section key={section.name}>
                        <SectionTitle>{section.name}</SectionTitle>
                        <SectionList>
                          {section.data.map((item) => (
                            <SectionItem key={item.label}>
                              <SectionLink href={item.link} target="_blank">
                                {item.label}
                              </SectionLink>
                            </SectionItem>
                          ))}
                        </SectionList>
                      </Section>
                    ))}
                    <Section
                      css={css`
                        min-width: 250px;
                      `}
                    >
                      <SectionTitle>Nos espaces</SectionTitle>
                      <LoginButtons>
                        <Button
                          target="_blank"
                          href={BROKER_SPACE_URL}
                          transparent
                          color="white"
                          leftIcon={<FaUserTie />}
                        >
                          Espace courtiers
                        </Button>
                        <Button target="_blank" href={CLIENT_SPACE_URL} transparent color="white" leftIcon={<FaUser />}>
                          Espace assurés
                        </Button>
                      </LoginButtons>
                    </Section>
                  </FooterSectionsContent>
                </FooterSectionsContainer>
                <FooterInfosContainer>
                  <FooterInfosContent>
                    <img src={logo} alt="logo" width="235" height="65" style={{ marginLeft: '30px' }} />
                    <FooterInfosText>{data.strapiFooterLayout.about}</FooterInfosText>
                  </FooterInfosContent>
                </FooterInfosContainer>
              </FooterDatasContent>
            </FooterDatasContentWrapper>
          </FooterDatas>
          <FooterLinkContainer>
            <PageWrapper style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ marginRight: dimensions.space.fourtyEight }}>Copyright © Looma 2021</div>
              <FooterLinkList>
                {data.allStrapiOther.edges.map(({ node }) => (
                  <FooterLinkItem key={node.name} {...node} />
                ))}
              </FooterLinkList>
            </PageWrapper>
          </FooterLinkContainer>
        </Wrapper>
      )}
    />
  );
};

export default Footer;
